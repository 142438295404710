import React from 'react';

function OmanAnalysis(props) {
  return (
    <div>
      <div className="min-h-[40vh] mt-4 bg-white rounded-lg shadow flex items-center justify-center" >
        <h1 className="text-2xl font-bold " >
          Coming soon
        </h1>
      </div>
    </div>
  );
}

export default OmanAnalysis;