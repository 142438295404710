import axios from "axios";

export const createApi = async () => {
  try {
    const configResponse = await fetch('/config.json');
    const config = await configResponse.json();

    const api = axios.create({ baseURL: `${config.baseApiUrl}/localapi/api` });
    return api;
  } catch (error) {
    console.error('Error fetching API configuration:', error);
    return axios.create();
  }
};

const api = await createApi();


export const getOmanCampaignStatistics = async (operatorId, year)=>{
  try {
    const response = await api.post('/oman-shares',{
      "OperatorId": operatorId,
      "year": year
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}