import React, {useEffect, useRef, useState} from "react";
import {FiChevronDown, FiChevronUp, FiX} from "react-icons/fi";
import cx from "classnames";

const removeDiacritics = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const CustomDropdownObject = ({label, isOpen, setIsOpen, itemList, selectedItem, itemKey, idKey, selectItem, disabled})=>{
  return (
    <div className="flex flex-col relative items-start">
      <label
        className='text-xs mb-0.5 ml-3 text-gray-500 font-semibold'>{label} *</label>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        disabled={disabled}
        className='relative border rounded-lg text-start px-2 py-1.5 text-sm font-semibold w-full'>
        {
          Object.keys(selectedItem).length > 0
            ? <span>{selectedItem[itemKey]}</span>
            : <span className="text-gray-400">{label}</span>
        }
        {
          isOpen
            ? <FiChevronUp className="text-gray-400 absolute right-3 top-2"/>
            : <FiChevronDown className="text-gray-400 absolute right-3 top-2"/>
        }
      </button>
      {
        isOpen &&
        <ul className="bg-white text-start absolute max-h-[200px] overflow-y-auto top-14 left-0 w-full border shadow rounded-lg p-1 text-sm font-semibold z-20">
          {
            itemList.map((item) =>
              <li
                key={item[idKey]}
                className={cx("py-1.5 px-2 hover:bg-sky-100 rounded-lg cursor-pointer", {'text-sky-600 bg-sky-100': item[idKey] === selectedItem[idKey]})}
                onClick={() => selectItem(item)}
              >
                {item[itemKey]}
              </li>
            )
          }
        </ul>
      }
    </div>
  )
}


export const CustomDropdownFilterObject = ({label, isOpen, setIsOpen, itemList, selectedItem, itemKey, idKey, selectItem, disabled})=>{

  return (
    <div className="flex flex-col relative items-start">
      <label className="text-gray-400 text-xs font-semibold mb-1 ml-2" >
        {label}
      </label>
      <button
        onClick={() => setIsOpen(!isOpen)}
        disabled={disabled}
        className='relative border rounded-lg text-start px-2 py-1.5 text-sm font-semibold w-full pr-8 bg-white truncate'>
        {
          Object.keys(selectedItem).length > 0
            ? <span className="truncate">{selectedItem[itemKey]}</span>
            : <span className="text-gray-400">{label}</span>
        }
        {
          isOpen
            ? <FiChevronUp className="text-gray-400 absolute right-3 top-2"/>
            : <FiChevronDown className="text-gray-400 absolute right-3 top-2"/>
        }
      </button>
      {
        isOpen &&
        <ul
          className="bg-white text-start absolute z-50 top-14 left-0 w-full border shadow rounded-lg p-1 text-sm font-semibold max-h-[340px] overflow-y-auto">
          {
            itemList.map((item) =>
              <li
                key={item[idKey]}
                className={cx("py-1.5 px-2 hover:bg-sky-100 rounded-lg cursor-pointer", {'text-sky-600 bg-sky-100': item[idKey] === selectedItem[idKey]})}
                onClick={() => selectItem(item)}
              >
                {item[itemKey]}
              </li>
            )
          }
        </ul>
      }
    </div>
  )
}
