import React, {useEffect, useState} from 'react';
import {
  FiBarChart,
  FiBarChart2,
  FiBriefcase,
  FiChevronRight,
  FiFile,
  FiHome,
  FiPieChart,
  FiUserPlus
} from "react-icons/fi";
import {useTranslation} from "react-i18next";
import Modal from "./Modal";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {getOmanCampaignStatistics} from "../services/customSdpApiService";

function StcRevenueModal(){

  const navigate = useNavigate();

  const [data, setData] = useState(null);

  useEffect(() => {
    axios
        .get('/data.json')
        .then((response) => {
          setData(response.data.STCRevenue);
        })
        .catch((error) => {
          console.error('Error fetching the JSON file:', error);
        });
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const totalRevenue = data.reduce((acc, row) => acc + parseFloat(row.revenue.replace(/,/g, '')), 0);
  const totalShare = data.reduce((acc, row) => acc + parseFloat(row.share.replace(/,/g, '')), 0);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
        <tr>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Month</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Total Revenue SAR</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Taqniyat Share</th>
        </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className="hover:bg-gray-100">
              <td onClick={()=> navigate('/revenue-analysis') } className="cursor-pointer border border-gray-300 px-4 py-2">{row.date}</td>
              <td className="border border-gray-300 px-4 py-2">{row.revenue}</td>
              <td className="border border-gray-300 px-4 py-2">{row.share}</td>
            </tr>
          ))}
          <tr className="font-bold bg-gray-200">
            <td className="py-2 px-4 border-t border-gray-300">Total</td>
            <td className="py-2 px-4 border-t border-gray-300">
              {totalRevenue.toLocaleString()}
            </td>
            <td className="py-2 px-4 border-t border-gray-300">
              {totalShare.toLocaleString()}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function MobilyRevenueModal() {

  const navigate = useNavigate();

  const [data, setData] = useState(null);

  useEffect(() => {
    axios
        .get('/data.json')
        .then((response) => {
          setData(response.data.MOBILYRevenue);
        })
        .catch((error) => {
          console.error('Error fetching the JSON file:', error);
        });
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const totalRevenue = data.reduce((acc, row) => acc + parseFloat(row.revenue.replace(/,/g, '')), 0);
  const totalShare = data.reduce((acc, row) => acc + parseFloat(row.share.replace(/,/g, '')), 0);

  return (
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">Month</th>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">Total Revenue SAR</th>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">Taqniyat Share</th>
          </tr>
          </thead>
          <tbody>
          {data.map((row, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td onClick={()=> navigate('/revenue-analysis') } className="cursor-pointer border border-gray-300 px-4 py-2">{row.date}</td>
                <td className="border border-gray-300 px-4 py-2">{row.revenue}</td>
                <td className="border border-gray-300 px-4 py-2">{row.share}</td>
              </tr>
          ))}
          <tr className="font-bold bg-gray-200">
            <td className="py-2 px-4 border-t border-gray-300">Total</td>
            <td className="py-2 px-4 border-t border-gray-300">
              {totalRevenue.toLocaleString()}
            </td>
            <td className="py-2 px-4 border-t border-gray-300">
              {totalShare.toLocaleString()}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
  );
}

function ZainRevenueModal(){

  const navigate = useNavigate();

  const [data, setData] = useState(null);

  useEffect(() => {
    axios
        .get('/data.json')
        .then((response) => {
          setData(response.data.ZAINRevenue);
        })
        .catch((error) => {
          console.error('Error fetching the JSON file:', error);
        });
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const totalRevenue = data.reduce((acc, row) => acc + parseFloat(row.revenue.replace(/,/g, '')), 0);
  const totalShare = data.reduce((acc, row) => acc + parseFloat(row.share.replace(/,/g, '')), 0);

  return (
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
          <tr>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">Month</th>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">Total Revenue SAR</th>
            <th className="border border-gray-300 px-4 py-2 bg-gray-200">Taqniyat Share</th>
          </tr>
          </thead>
          <tbody>
          {data.map((row, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td onClick={()=> navigate('/revenue-analysis') } className="cursor-pointer border border-gray-300 px-4 py-2">{row.date}</td>
                <td className="border border-gray-300 px-4 py-2">{row.revenue}</td>
                <td className="border border-gray-300 px-4 py-2">{row.share}</td>
              </tr>
          ))}
          <tr className="font-bold bg-gray-200">
            <td className="py-2 px-4 border-t border-gray-300">Total</td>
            <td className="py-2 px-4 border-t border-gray-300">
              {totalRevenue.toLocaleString()}
            </td>
            <td className="py-2 px-4 border-t border-gray-300">
              {totalShare.toLocaleString()}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
  );
}

function ZainFGCRevenueModal(){

  const navigate = useNavigate();

  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get('/data.json')
      .then((response) => {
        setData(response.data.ZAIN_FGCRevenue);
      })
      .catch((error) => {
        console.error('Error fetching the JSON file:', error);
      });
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const totalRevenue = data.reduce((acc, row) => acc + parseFloat(row.revenue.replace(/,/g, '')), 0);
  const totalShare = data.reduce((acc, row) => acc + parseFloat(row.share.replace(/,/g, '')), 0);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
        <tr>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Month</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Total Revenue SAR</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Taqniyat Share</th>
        </tr>
        </thead>
        <tbody>
        {data.map((row, index) => (
          <tr key={index} className="hover:bg-gray-100">
            <td onClick={()=> navigate('/revenue-analysis') } className="cursor-pointer border border-gray-300 px-4 py-2">{row.date}</td>
            <td className="border border-gray-300 px-4 py-2">{row.revenue}</td>
            <td className="border border-gray-300 px-4 py-2">{row.share}</td>
          </tr>
        ))}
        <tr className="font-bold bg-gray-200">
          <td className="py-2 px-4 border-t border-gray-300">Total</td>
          <td className="py-2 px-4 border-t border-gray-300">
            {totalRevenue.toLocaleString()}
          </td>
          <td className="py-2 px-4 border-t border-gray-300">
            {totalShare.toLocaleString()}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}

function OmanTelRevenueModal(){
  const navigate = useNavigate();

  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get('/data.json')
      .then((response) => {
        setData(response.data.OMANTELRevenue);
      })
      .catch((error) => {
        console.error('Error fetching the JSON file:', error);
      });
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const totalRevenue = data.reduce((acc, row) => acc + parseFloat(row.revenue.replace(/,/g, '')), 0);
  const totalShare = data.reduce((acc, row) => acc + parseFloat(row.share.replace(/,/g, '')), 0);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
        <tr>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Month</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Total Revenue SAR</th>
          <th className="border border-gray-300 px-4 py-2 bg-gray-200">Taqniyat Share</th>
        </tr>
        </thead>
        <tbody>
        {data.map((row, index) => (
          <tr key={index} className="hover:bg-gray-100">
            <td onClick={()=> navigate('/revenue-analysis') } className="cursor-pointer border border-gray-300 px-4 py-2">{row.date}</td>
            <td className="border border-gray-300 px-4 py-2">{row.revenue}</td>
            <td className="border border-gray-300 px-4 py-2">{row.share}</td>
          </tr>
        ))}
        <tr className="font-bold bg-gray-200">
          <td className="py-2 px-4 border-t border-gray-300">Total</td>
          <td className="py-2 px-4 border-t border-gray-300">
            {totalRevenue.toLocaleString()}
          </td>
          <td className="py-2 px-4 border-t border-gray-300">
            {totalShare.toLocaleString()}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}

function Dashboard(props) {

  const { t, i18n } = useTranslation();

  const [totals, setTotals] = useState({
    STCRevenue: { revenue: 0, share: 0 },
    MOBILYRevenue: { revenue: 0, share: 0 },
    ZAINRevenue: { revenue: 0, share: 0 },
    ZAIN_FGCRevenue: { revenue: 0, share: 0 },
  });
  const [overallTotal, setOverallTotal] = useState({ revenue: '0', share: '0' });

  const [totalsOMAN, setTotalsOMAN] = useState({
    OMANTEL: { revenue: 0, share: 0 },
    OOREDOO: { revenue: 0, share: 0 },
  });
  const [overallTotalOMAN, setOverallTotalOMAN] = useState({ revenue: '0', share: '0' });

  const [isStcModalOpen, setIsStcModalOpen] = useState(false);
  const [isMobilyModalOpen, setIsMobilyModalOpen] = useState(false);
  const [isZainModalOpen, setIsZainModalOpen] = useState(false);
  const [isZainFGCModalOpen, setIsZainFGCModalOpen] = useState(false);
  const [isOmanTelModalOpen, setIsOmanTelModalOpen] = useState(false);

  const [campaignStatisticsOmanTel, setCampaignStatisticsOmanTel] = useState({});
  const [campaignStatisticsOreddo, setCampaignStatisticsOreddo] = useState({});

  const [lastUpdate, setLastUpdate] = useState({
    STC: "",
    MOBILY: "",
    ZAIN: "",
    ZAIN_FGCRevenue: "",
    OMANTEL: "",
    OOREDOO: ""
  });

  const openStcModal = ()=>{
    setIsStcModalOpen(true);
  }
  const closeStcModal = ()=>{
    setIsStcModalOpen(false);
  }
  const openMobilyModal = ()=>{
    setIsMobilyModalOpen(true);
  }
  const closeMobilyModal = ()=>{
    setIsMobilyModalOpen(false);
  }
  const openZainModal = ()=>{
    setIsZainModalOpen(true);
  }
  const closeZainModal = ()=>{
    setIsZainModalOpen(false);
  }
  const openZainFGCModal = ()=>{
    setIsZainFGCModalOpen(true);
  }
  const closeZainFGCModal = ()=>{
    setIsZainFGCModalOpen(false);
  }
  const openOmanTelModal = ()=>{
    setIsOmanTelModalOpen(true);
  }
  const closeOmanTelModal = ()=>{
    setIsOmanTelModalOpen(false);
  }

  const findLastUpdate = (revenueData) => {
    const lastEntry = revenueData.reverse().find(entry => parseFloat(entry.revenue) !== 0);
    return lastEntry ? lastEntry.date : "";
  };

  const fetchLastUpdate = () => {
    axios.get('/data.json')
      .then((response) => {
        const { STCRevenue, MOBILYRevenue, ZAINRevenue, ZAIN_FGCRevenue, OMANTELRevenue, OOREDOORevenue } = response.data;

        setLastUpdate(prevState => ({
          STC: findLastUpdate([...STCRevenue]),
          MOBILY: findLastUpdate([...MOBILYRevenue]),
          ZAIN: findLastUpdate([...ZAINRevenue]),
          ZAIN_FGCRevenue: findLastUpdate([...ZAIN_FGCRevenue]),
          OMANTEL: findLastUpdate([...OMANTELRevenue]),
          OOREDOO: findLastUpdate([...OOREDOORevenue]),
        }));

      })
      .catch((error) => {
        console.error('Error fetching the JSON file:', error);
      });
  }

  const fetchSTCData = ()=>{
    axios.get('/data.json')
        .then((response) => {
          const { STCRevenue, MOBILYRevenue, ZAINRevenue, ZAIN_FGCRevenue } = response.data;

          const calculateTotal = (dataArray) => {
            let revenueTotal = 0;
            let shareTotal = 0;

            dataArray.forEach(item => {
              revenueTotal += parseFloat(item.revenue.replace(/,/g, ''));
              shareTotal += parseFloat(item.share.replace(/,/g, ''));
            });

            return {
              revenue: revenueTotal,
              share: shareTotal,
            };
          };

          const stcTotal = calculateTotal(STCRevenue);
          const mobilyTotal = calculateTotal(MOBILYRevenue);
          const zainTotal = calculateTotal(ZAINRevenue);
          const zainFgcTotal = calculateTotal(ZAIN_FGCRevenue);

          setTotals({
            STCRevenue: {
              revenue: stcTotal.revenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              share: stcTotal.share.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            },
            MOBILYRevenue: {
              revenue: mobilyTotal.revenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              share: mobilyTotal.share.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            },
            ZAINRevenue: {
              revenue: zainTotal.revenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              share: zainTotal.share.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            },
            ZAIN_FGCRevenue: {
              revenue: zainFgcTotal.revenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              share: zainFgcTotal.share.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            }
          });

          const overallRevenueTotal = stcTotal.revenue + mobilyTotal.revenue + zainTotal.revenue + zainFgcTotal.revenue;
          const overallShareTotal = stcTotal.share + mobilyTotal.share + zainTotal.share + zainFgcTotal.share;

          setOverallTotal({
            revenue: overallRevenueTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            share: overallShareTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          });
        })
        .catch((error) => {
          console.error('Error fetching the JSON file:', error);
        });
  }

  const fetchOMANData = ()=>{
    axios.get('/data.json')
        .then((response) => {
          const { OMANTELRevenue, OOREDOORevenue } = response.data;

          const calculateTotal = (dataArray) => {
            let revenueTotal = 0;
            let shareTotal = 0;

            dataArray.forEach(item => {
              revenueTotal += parseFloat(item.revenue.replace(/,/g, ''));
              shareTotal += parseFloat(item.share.replace(/,/g, ''));
            });

            return {
              revenue: revenueTotal || 0,
              share: shareTotal || 0,
            };
          };

          const omanTelTotal = calculateTotal(OMANTELRevenue);
          const ooredooTotal = calculateTotal(OOREDOORevenue);

          setTotalsOMAN({
            OMANTEL: {
              revenue: omanTelTotal.revenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              share: omanTelTotal.share.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            },
            OOREDOO: {
              revenue: ooredooTotal.revenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
              share: ooredooTotal.share.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            },
          });

          const overallRevenueTotal = omanTelTotal.revenue + ooredooTotal.revenue;
          const overallShareTotal = omanTelTotal.share + ooredooTotal.share;

          setOverallTotalOMAN({
            revenue: overallRevenueTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            share: overallShareTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          });
        })
        .catch((error) => {
          console.error('Error fetching the JSON file:', error);
        });
  }

  const fetchOmanCampaignStatisticsOmanTel = async ()=>{
    await getOmanCampaignStatistics(5, 2024)
      .then((res)=>{
        setCampaignStatisticsOmanTel(res[0]);
      })
      .catch((err)=>{console.log(err)});
  }

  const fetchOmanCampaignStatisticsOreddo = async ()=>{
    await getOmanCampaignStatistics(8, 2024)
      .then((res)=>{
        setCampaignStatisticsOreddo(res[0]);
      })
      .catch((err)=>{console.log(err)});
  }

  useEffect(() => {
    fetchSTCData();
    fetchOMANData();
    fetchLastUpdate();
    fetchOmanCampaignStatisticsOmanTel();
    fetchOmanCampaignStatisticsOreddo();
  }, []);

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("GLOBAL.DASHBOARD")}</h4>
      </div>
      <div className="grid grid-cols-2 gap-5">
        <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-y-auto flex-nowrap text-sm'>
          <table className="table-auto w-full border-collapse border border-gray-300">
            <thead>
            <tr>
              <th colSpan="4"
                  className="border border-gray-300 px-4 py-2 bg-azure text-white text-center text-lg font-semibold">
                Total Market revenue 2024
              </th>
            </tr>
            <tr>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Country</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Total Revenue SAR</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Taqniyat Share</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Last update</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className="border border-gray-300 px-4 py-2">KSA</td>
              <td className="border border-gray-300 px-4 py-2">{overallTotal.revenue} </td>
              <td className="border border-gray-300 px-4 py-2">{overallTotal.share}</td>
              <td className="border border-gray-300 px-4 py-2">{lastUpdate.STC}</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">OMAN</td>
              <td className="border border-gray-300 px-4 py-2">{overallTotalOMAN.revenue}</td>
              <td className="border border-gray-300 px-4 py-2">{overallTotalOMAN.share}</td>
              <td className="border border-gray-300 px-4 py-2">{lastUpdate.OMANTEL}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-y-auto flex-nowrap text-sm'>
          <table className="table-auto w-full border-collapse border border-gray-300">
            <thead>
            <tr>
              <th colSpan="4"
                  className="border border-gray-300 px-4 py-2 bg-azure text-white text-center text-lg font-semibold">
                Market revenue KSA 2024
              </th>
            </tr>
            <tr>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Operator</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Total Revenue SAR</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Taqniyat Share</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Last update</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td
                onClick={() => openStcModal()}
                className="border cursor-pointer hover:bg-gray-100 border-gray-300 px-4 py-2">
                STC
              </td>
              <td className="border border-gray-300 px-4 py-2">{totals.STCRevenue.revenue}</td>
              <td className="border border-gray-300 px-4 py-2">{totals.STCRevenue.share}</td>
              <td className="border border-gray-300 px-4 py-2">{lastUpdate.STC}</td>
            </tr>
            <tr>
              <td
                onClick={() => openMobilyModal()}
                className="border cursor-pointer hover:bg-gray-100 border-gray-300 px-4 py-2">
                MOBILY
              </td>
              <td className="border border-gray-300 px-4 py-2">{totals.MOBILYRevenue.revenue}</td>
              <td className="border border-gray-300 px-4 py-2">{totals.MOBILYRevenue.share}</td>
              <td className="border border-gray-300 px-4 py-2">{lastUpdate.MOBILY}</td>
            </tr>
            <tr>
              <td
                onClick={() => openZainModal()}
                className="border cursor-pointer hover:bg-gray-100 border-gray-300 px-4 py-2">
                ZAIN
              </td>
              <td className="border border-gray-300 px-4 py-2">{totals.ZAINRevenue.revenue}</td>
              <td className="border border-gray-300 px-4 py-2">{totals.ZAINRevenue.share}</td>
              <td className="border border-gray-300 px-4 py-2">{lastUpdate.ZAIN}</td>
            </tr>
            <tr>
              <td
                onClick={() => openZainFGCModal()}
                className="border cursor-pointer hover:bg-gray-100 border-gray-300 px-4 py-2">
                ZAIN-FGC
              </td>
              <td className="border border-gray-300 px-4 py-2">{totals.ZAIN_FGCRevenue.revenue}</td>
              <td className="border border-gray-300 px-4 py-2">{totals.ZAIN_FGCRevenue.share}</td>
              <td className="border border-gray-300 px-4 py-2">{lastUpdate.ZAIN_FGCRevenue}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-y-auto flex-nowrap text-sm'>
          <table className="table-auto w-full border-collapse border border-gray-300">
            <thead>
            <tr>
              <th colSpan="4"
                  className="border border-gray-300 px-4 py-2 bg-azure text-white text-center text-lg font-semibold">
                Market revenue OMAN 2024
              </th>
            </tr>
            <tr>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Operator</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Total Revenue SAR</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Taqniyat Share</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Last update</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td onClick={() => openOmanTelModal()} className="cursor-pointer border border-gray-300 px-4 py-2">OMAN
                TEL
              </td>
              <td className="border border-gray-300 px-4 py-2">{totalsOMAN.OMANTEL.revenue}</td>
              <td className="border border-gray-300 px-4 py-2">{totalsOMAN.OMANTEL.share}</td>
              <td className="border border-gray-300 px-4 py-2">{lastUpdate.OMANTEL}</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">OOREDOO</td>
              <td className="border border-gray-300 px-4 py-2">{totalsOMAN.OOREDOO.revenue}</td>
              <td className="border border-gray-300 px-4 py-2">{totalsOMAN.OOREDOO.share}</td>
              <td className="border border-gray-300 px-4 py-2">{lastUpdate.OOREDOO}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-y-auto flex-nowrap text-sm'>
          <table className="table-auto w-full border-collapse border border-gray-300">
            <thead>
            <tr>
              <th colSpan="4"
                  className="border border-gray-300 px-4 py-2 bg-azure text-white text-center text-lg font-semibold">
                Campaign statistics OMAN 2024
              </th>
            </tr>
            <tr>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Operator</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Spent SAR</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Revenue estimated SAR</th>
              <th className="border border-gray-300 px-4 py-2 bg-gray-200">Total cost SAR</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className="cursor-pointer border border-gray-300 px-4 py-2">
                OMAN TEL
              </td>
              <td className="border border-gray-300 px-4 py-2">{campaignStatisticsOmanTel.Spent?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              <td className="border border-gray-300 px-4 py-2">{campaignStatisticsOmanTel.RevenueEstimated?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              <td className="border border-gray-300 px-4 py-2">{campaignStatisticsOmanTel.ROI?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">OOREDOO</td>
              <td className="border border-gray-300 px-4 py-2">{campaignStatisticsOreddo.Spent?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              <td className="border border-gray-300 px-4 py-2">{campaignStatisticsOreddo.RevenueEstimated?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
              <td className="border border-gray-300 px-4 py-2">{campaignStatisticsOreddo.ROI?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <Modal isOpen={isStcModalOpen} close={closeStcModal} title="KSA - STC revenue">
        <StcRevenueModal/>
      </Modal>
      <Modal isOpen={isMobilyModalOpen} close={closeMobilyModal} title="KSA - MOBILY revenue">
        <MobilyRevenueModal/>
      </Modal>
      <Modal isOpen={isZainModalOpen} close={closeZainModal} title="KSA - ZAIN revenue">
        <ZainRevenueModal/>
      </Modal>
      <Modal isOpen={isZainFGCModalOpen} close={closeZainFGCModal} title="KSA - ZAIN-FGC revenue">
        <ZainFGCRevenueModal/>
      </Modal>
      <Modal isOpen={isOmanTelModalOpen} close={closeOmanTelModal} title="OMAN - OMAN TEL revenue">
        <OmanTelRevenueModal/>
      </Modal>
    </div>
  );
}

export default Dashboard;
