import React, {useEffect, useState} from 'react';
import {FiChevronRight, FiHome} from "react-icons/fi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import cx from "classnames";
import moment from "moment/moment";
import {
  addTextContents,
  getAllContents, getAllContentsByOperator, getAllContentsByService,
  getAllContentServices,
  getAllContentServicesByOperator
} from "../services/contentManagementService";
import {CustomDropdownFilterObject, CustomDropdownObject} from "../ressources/CustomComponents";
import {getAllOperators} from "../services/technicalService";
import Modal from "./Modal";
import DatePicker from "react-datepicker";

function AddTextModal({close}){

  const {t} = useTranslation();

  const [isOperatorSelectOpen, setIsOperatorSelectOpen] = useState(false);
  const [operators, setOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState({});
  const [isContentServiceSelectOpen, setIsContentServiceSelectOpen] = useState(false);
  const [contentServices, setContentServices] = useState([]);
  const [selctedContentService, setSelctedContentService] = useState({});
  const [date, setDate] = useState(null);
  const [msg, setMsg] = useState("");

  const [error, setError] = useState(null);

  const selectOperator = (val) => {
    setSelectedOperator(val);
    setIsOperatorSelectOpen(false);
  }

  const selectContentService = (val) => {
    setSelctedContentService(val);
    setIsContentServiceSelectOpen(false);
  }

  const submitData = async ()=>{
    if(Object.keys(selectedOperator).length === 0 || Object.keys(selctedContentService).length === 0 || date === null || msg.length === 0){
      setError("ALL_FIELD_REQUIRED");
    }else{
      const updatedDate = new Date(date);
      updatedDate.setHours(13, 0, 0, 0);
      let data = {
        serviceId: selctedContentService.serviceId,
        dateToSend: updatedDate,
        operatorId: selectedOperator.operatorId,
        contentMessage: msg
      };
      await addTextContents(data)
        .then((res)=>{
          console.log(res);
        })
        .catch((err)=>{
          console.log(err);
        })
    }
  }

  const fetchOperators = async ()=>{
    await getAllOperators()
      .then((res)=> setOperators(res.results) )
      .catch((err)=>{console.log(err)});
  }
  const fetchContentServices = async ()=>{
    await getAllContentServices()
      .then((res)=> setContentServices(res.results) )
      .catch((err)=>{console.log(err)});
  }

  useEffect(() => {
    fetchOperators();
    fetchContentServices();
  }, []);

  return(
    <div className="min-h-[300px] flex flex-col gap-5 justify-between ">
      { error && <p className="text-sm font-semibold text-red-700" >{t(`ERRORS.${error}`)}</p>}
      <div>
        <div className="grid grid-cols-3 gap-3 mb-4">
          <CustomDropdownObject
            label="Choose Operator"
            isOpen={isOperatorSelectOpen}
            setIsOpen={setIsOperatorSelectOpen}
            itemList={operators}
            selectedItem={selectedOperator}
            itemKey="operatorName"
            idKey="operatorId"
            selectItem={selectOperator}
          />
          <CustomDropdownObject
            label="Choose Service"
            isOpen={isContentServiceSelectOpen}
            setIsOpen={setIsContentServiceSelectOpen}
            itemList={contentServices}
            selectedItem={selctedContentService}
            itemKey="serviceName"
            idKey="serviceId"
            selectItem={selectContentService}
          />
          <div className='flex flex-col items-center justify-start'>
            <label className='text-xs w-full text-left mb-0.5 ml-2.5 text-gray-500 font-semibold'>Date to send *</label>
            <DatePicker
              selected={date}
              onChange={(date) => setDate(date)}
              isClearable
              dateFormat="dd/MM/yyyy"
              className='border rounded-lg px-2 py-1.5 text-sm w-full'
              wrapperClassName='w-full'
              placeholderText="Date to send"
            />
          </div>
        </div>
        <div className='flex flex-col items-center justify-start'>
          <label className='text-xs w-full text-left mb-0.5 ml-2.5 text-gray-500 font-semibold'>Message *</label>
          <textarea
            className='border rounded-lg px-2 py-1.5 text-sm w-full'
            value={msg}
            placeholder="Message"
            rows={5}
            onChange={(e) => setMsg(e.target.value)}
          />
        </div>
      </div>
      <button
        onClick={()=> submitData() }
        className="cursor-pointer bg-azure px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow">
        {t("GLOBAL.SAVE")}
      </button>
    </div>
  )
}

function ContentManagement(props) {

  const {t} = useTranslation();

  const [isOperatorSelectOpen, setIsOperatorSelectOpen] = useState(false);
  const [operators, setOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState({});
  const [isContentServiceSelectOpen, setIsContentServiceSelectOpen] = useState(false);
  const [contentServices, setContentServices] = useState([]);
  const [selctedContentService, setSelctedContentService] = useState({});

  const [contentsData, setContentsData] = useState([]);

  const [isAddTextModalOpen, setIsAddTextModalOpen] = useState(false);

  const openAddTextModal = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsAddTextModalOpen(true);
  }
  const closeAddTextModal = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsAddTextModalOpen(false);
  }

  const selectOperator = (val) => {
    if (val.operatorId === 0) {
      setSelectedOperator({});
    } else {
      setSelectedOperator(val);
    }
    setIsOperatorSelectOpen(false);
  }

  const selectContentService = (val) => {
    if (val.serviceId === 0) {
      setSelctedContentService({});
    } else {
      setSelctedContentService(val);
    }
    setIsContentServiceSelectOpen(false);
  }

  const fetchContentsByOperator = async () => {
    await getAllContentsByOperator(selectedOperator.operatorId)
      .then((res) => {
        setContentsData(res.results);
      })
      .catch((err)=>{console.log(err)});
  }

  const fetchContentsByService = async () => {
    await getAllContentsByService(selctedContentService.serviceId)
      .then((res)=>{
        setContentsData(res.results);
      })
      .catch((err)=>{console.log(err)});
  }

  const fetchAllContents = async () => {
    await getAllContents()
      .then((res)=>{
        setContentsData(res.results);
      })
      .catch((err)=>{console.log(err)});
  }

  const fetchOperators = async ()=>{
    await getAllOperators()
      .then((res)=> setOperators(res.results) )
      .catch((err)=>{console.log(err)});
  }
  const fetchContentServices = async ()=>{
    await getAllContentServices()
      .then((res)=> setContentServices(res.results) )
      .catch((err)=>{console.log(err)});
  }

  useEffect(() => {
    if(Object.keys(selectedOperator).length > 0){
      fetchContentsByOperator();
    }else if(Object.keys(selctedContentService).length > 0){
      fetchContentsByService();
    } else{
      fetchAllContents();
    }
  }, [selectedOperator, selctedContentService]);

  useEffect(() => {
    fetchOperators();
    fetchContentServices();
  }, []);

  return (
    <div>
      <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
        <span className="text-xs font-semibold flex items-center">
          <FiHome size={12}/> <FiChevronRight size={12}/>
        </span>
        <h4 className="text-xs font-semibold text-gray-500">{t("CONTENT_MANAGEMENT.TITLE")}</h4>
      </div>
      <div className="flex items-center justify-between gap-5 mb-6">
        <div className="w-3/4 flex items-center gap-5" >
          <div className="w-1/3">
            <CustomDropdownFilterObject
              label="Operators"
              isOpen={isOperatorSelectOpen}
              setIsOpen={setIsOperatorSelectOpen}
              itemList={[{operatorId: 0, operatorName: "See All"}, ...operators]}
              selectedItem={selectedOperator}
              itemKey="operatorName"
              idKey="operatorId"
              selectItem={selectOperator}
            />
          </div>
          <div className="w-1/3">
            <CustomDropdownFilterObject
              label="Content services"
              isOpen={isContentServiceSelectOpen}
              setIsOpen={setIsContentServiceSelectOpen}
              itemList={[{serviceId: 0, serviceName: "See All"}, ...contentServices]}
              selectedItem={selctedContentService}
              itemKey="serviceName"
              idKey="serviceId"
              selectItem={selectContentService}
            />
          </div>
        </div>
        <button
          onClick={()=> openAddTextModal() }
          className="cursor-pointer bg-azure px-4 py-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow">
          {t("CONTENT_MANAGEMENT.ADD_BTN")}
        </button>
      </div>
      <div className='bg-white p-3 rounded-lg mt-4 shadow overflow-x-auto'>
        <table className="min-w-full border-collapse" id='my-applications-table'>
          <thead className="bg-gray-100 customShadow">
          <tr>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CONTENT_MANAGEMENT.TABLE.CONTENT_NAME")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CONTENT_MANAGEMENT.TABLE.DATE_TO_SEND")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CONTENT_MANAGEMENT.TABLE.SHORT_URL_ENGLISH")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CONTENT_MANAGEMENT.TABLE.LONG_URL_ENGLISH")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CONTENT_MANAGEMENT.TABLE.SERVICE_NAME")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CONTENT_MANAGEMENT.TABLE.OPERATOR_NAME")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CONTENT_MANAGEMENT.TABLE.CONTENT_MESSAGE")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CONTENT_MANAGEMENT.TABLE.CONTENT_SENT")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CONTENT_MANAGEMENT.TABLE.CONTENT_APPROVED")}
            </th>
            <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
              {t("CONTENT_MANAGEMENT.TABLE.CONTENT_CREATED")}
            </th>
          </tr>
          </thead>
          <tbody className="text-gray-800 text-sm">
          {
            contentsData.map((it) =>
              <tr key={it.contentId} className={cx('border')}>
                <td className="text-left py-3 px-2">{it.contentName}</td>
                <td className="text-left py-3 px-2">{moment(it.dateToSend).format('YYYY-MM-DD')}</td>
                <td className="text-left py-3 px-2">{it.shortUrlEnglish}</td>
                <td className="text-left py-3 px-2">{it.longUrlEnglish}</td>
                <td className="text-left py-3 px-2">{it.serviceName}</td>
                <td className="text-left py-3 px-2">{it.operatorName}</td>
                <td className="text-left py-3 px-2">{it.contentMessage}</td>
                <td className="text-left py-3 px-2">{it.contentSent}</td>
                <td className="text-left py-3 px-2">{it.contentApproved}</td>
                <td className="text-left py-3 px-2">{moment(it.contentCreated).format('YYYY-MM-DD')}</td>
              </tr>
            )
          }
          </tbody>
        </table>
      </div>

      <Modal isOpen={isAddTextModalOpen} close={closeAddTextModal} title="Add text content">
        <AddTextModal close={closeAddTextModal}/>
      </Modal>
    </div>
  );
}

export default ContentManagement;
