import React, {useContext, useEffect, useRef, useState} from 'react';
import { useNavigate, Link, Outlet, useLocation } from 'react-router-dom';
import cx from 'classnames';
import {AnimatePresence, motion} from "framer-motion";

import adminLogo from '../assets/AdminLTELogo.png';
import {
  FiBarChart,
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight, FiChevronUp, FiCircle,
  FiGlobe,
  FiGrid, FiList,
  FiLogOut, FiMenu, FiSidebar, FiTable, FiTrello,
  FiUser,
  FiUserCheck,
  FiUsers
} from 'react-icons/fi';
import {FaUsers} from "react-icons/fa";
import {loginEvent} from "../Context/events";
import {UserContext} from "../Context/UserContext";
import MobileSideMenu from "./MobileSideMenu";

const MenuData = [
  {id: 1, title: "Dashboard", icon: <FiGrid/>, route: "/"},
  {id: 2, title: "Monthly analysis", icon: <FiBarChart/>, subData: [
      {id: 1, title: "KSA", route: "/ksa-analysis"},
      {id: 2, title: "OMAN", route: "/oman-analysis"},
    ]
  },
  {id: 3, title: "Partners Management", icon: <FaUsers/>, subData: [
      {id: 1, title: "Partners", route: "/partners"},
      {id: 2, title: "Partner Services", route: "/partners-services"},
      {id: 3, title: "Revenue Statistics", route: "/partners-revenue"},
    ]
  },
  {id: 4, title: "Service Management", subData: [
      {id: 1, title: "Services", route: "/services"},
      {id: 2, title: "Service Statistics", route: "/service-statistics"},
    ]
  },
  {id: 5, title: "Content Management", subData: [
      {id: 1, title: "Content services", route: "/content-services"},
      {id: 2, title: "Contents", route: "/content-management"},
    ]
  },
  {id: 6, title: "Campaign Management", subData: [
      {id: 1, title: "Campaigns", route: "/campaigns"},
      {id: 2, title: "Publishers", route: "/publisher"},
      {id: 3, title: "Registered Email Contacts", route: "/registered-emails"},
      {id: 4, title: "Campaign statistics", route: "/campaign-statistics"},
    ]
  },
  {id: 7, title: "Technical", subData: [
      {id: 1, title: "Dashboard", route: "/technical-dashboard"},
      {id: 2, title: "Operator", route: "/operator"},
      {id: 3, title: "Methods", route: "/methods"},
      {id: 4, title: "Operator credentials", route: "/operator-credentials"},
    ]
  },
  {id: 8, title: "Operator reports upload", subData: [
      {id: 1, title: "STC financial reports", route: "/operatorReports/stc-financial"},
      {id: 2, title: "Mobily financial reports", route: "/operatorReports/mobily-financial"},
      {id: 3, title: "Zain financial reports", route: "/operatorReports/zain-financial"},
      {id: 4, title: "Mobily", route: "/operatorReports/mobily"},
      {id: 5, title: "Zain", route: "/operatorReports/zain"},
    ]
  },
  {id: 9, title: "Administration", subData: [
      {id: 1, title: "Company", route: "/company"},
      {id: 2, title: "Partner", route: "/partners"},
      {id: 3, title: "Publisher", route: "/publisher"},
    ]
  },
];

function MenuItem({item, isBarOpen, selectedNav}){
  return (
    <li>
      <Link to={item.route}
            className={cx('flex border border-white items-center gap-2 p-2 rounded-lg cursor-pointer hover:border-gray-300', {
              'bg-azure text-white': selectedNav === item.route,
              'justify-center': !isBarOpen
            })}>
        {item.icon}
        {isBarOpen && <span className='text-sm font-semibold'>{item.title}</span>}
      </Link>
    </li>
  )
}

function DropMenuItem({item, isBarOpen, selectedNav}) {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <li className="w-full">
      <button
        title={item.title}
        onClick={() => setIsOpen(!isOpen)}
        className={cx('flex border border-white items-center w-full justify-between gap-2 p-2 rounded-lg cursor-pointer hover:border-gray-300')}
      >
        <div className={cx("flex items-center gap-2 ", {'w-[85%]': isBarOpen, 'w-full justify-center': !isBarOpen})} >
          {
            item.icon
              ? item.icon
              : <FiTrello/>
          }
          {isBarOpen && <span className='text-sm font-semibold text-left truncate'>{item.title}</span>}
        </div>
        {
          isBarOpen &&
          <div>
            {
              isOpen
                ? <FiChevronUp size={18} className="text-gray-500"/>
                : <FiChevronDown size={18} className="text-gray-500"/>
            }
          </div>
        }
      </button>
      {
        isOpen &&
        <div className="bg-gray-100 rounded-lg p-2 w-full" >
          {
            item.subData.map((subItem, index) =>
              <Link
                title={subItem.title}
                to={subItem.route}
                key={subItem.id}
                className={cx('flex border border-white items-center gap-2 p-2 rounded-lg cursor-pointer hover:border-gray-300', {
                  'bg-azure text-white': selectedNav === subItem.route,
                  'justify-center': !isBarOpen
                })}>
                <FiCircle/>
                {isBarOpen && <span className='text-sm font-semibold text-left truncate'>{subItem.title}</span>}
              </Link>
            )
          }
        </div>
      }
    </li>
  )
}

function Root(props) {

  const location = useLocation();
  const {user} = useContext(UserContext);

  const accountMenuRef = useRef(null);
  const langMenuRef = useRef(null);

  const [selectedNav, setSelectedNav] = useState("/");
  const [isBarOpen, setBarOpen] = useState(true);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const openMobileMenu = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsMobileMenuOpen(true);
  }
  const closeMobileMenu = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsMobileMenuOpen(false);
  }

  const logOut = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    loginEvent.emit('login', false);
  }

  const handleClickOutside = (event) => {
    if (accountMenuRef.current && !accountMenuRef.current.contains(event.target)) {
      setIsAccountMenuOpen(false);
    }
    if (langMenuRef.current && !langMenuRef.current.contains(event.target)) {
      setIsLangMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if(location.pathname !== selectedNav ){
      setSelectedNav(location.pathname);
    }
  }, [location]);

  return (
    <div className="flex justify-end bg-[#FAFAFA] min-h-[100vh]">
      <motion.div
        initial={false}
        animate={{
          width: isBarOpen ? '15rem' : '4rem',
          transition: {duration: 0.3, ease: "easeInOut"}
        }}
        className={cx("hidden lg:block fixed top-0 left-0 px-2 py-4 h-[100vh] bg-white customShadow", {
          'w-56': isBarOpen,
          'w-16': !isBarOpen
        })}
        // className={cx("fixed top-0 left-0 px-2 py-4 h-[100vh] bg-white customShadow", {'w-56': isBarOpen, 'w-16': !isBarOpen})}
      >
        <div className="relative flex justify-center items-center gap-4 mb-4">
          <div className={cx({'w-[4rem] h-16': isBarOpen, 'w-12 h-12': !isBarOpen})}>
            <img src={adminLogo} alt="visio bpm" className="h-full w-full object-contain"/>
          </div>
          {isBarOpen &&
            <p className="text-sm">
              <span className="font-semibold text-base">Taqniyat </span>
              tech
            </p>
          }
          <button className="absolute right-[-22px] top-8 border rounded-full p-1 bg-white"
                  onClick={() => setBarOpen(!isBarOpen)}>
            {
              isBarOpen
                ? <FiList size={16} className="h-4 w-4 object-contain"/>
                : <FiSidebar size={16} className="h-4 w-4 object-contain"/>
            }
          </button>
        </div>
        <ul className="flex flex-col gap-2">
          {
            MenuData.map(item => {
              if (item.subData && item.subData.length > 0) {
                return <DropMenuItem key={item.id} item={item} isBarOpen={isBarOpen} selectedNav={selectedNav}/>
              } else {
                return <MenuItem key={item.id} item={item} isBarOpen={isBarOpen} selectedNav={selectedNav}/>
              }
            })
          }
        </ul>
      </motion.div>
      <motion.div
        initial={false}
        animate={{
          width: isBarOpen ? 'calc(100vw - 15rem)' : 'calc(100vw - 4rem)',
          transition: {duration: 0.3, ease: "easeInOut"}
        }}
        className={cx("px-8 py-3 hidden lg:block")}
      >
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-light ">Service delivery platform</h1>
          <div className="flex items-center gap-2">
            <div className="relative">
              <button onClick={() => setIsAccountMenuOpen(true)}
                      className="flex items-center gap-2 rounded-full text-gray-500 bg-white px-2 py-1">
                <FiUser size={14}/>
                <span className="text-xs font-semibold">{user.firstName} {user.lastName}</span>
              </button>
              {isAccountMenuOpen &&
                <div ref={accountMenuRef}
                     className='absolute z-20 py-2 w-max bottom-[-4px] translate-y-[100%] right-0 bg-white customShadow rounded-lg overflow-y-auto overflow-x-hidden'>
                  <ul className='flex flex-col gap-2'>
                    <li>
                      <Link onClick={() => setIsAccountMenuOpen(false)} to="/account"
                            className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200'>
                        <FiUser/>
                        <span>Compte</span>
                      </Link>
                    </li>
                    <li onClick={() => logOut()}
                        className='flex px-2 py-1 text-xs items-center gap-2 text-gray-600 cursor-pointer hover:bg-gray-200'>
                      <FiLogOut/>
                      <span>Log out</span>
                    </li>
                  </ul>
                </div>
              }
            </div>
          </div>
        </div>
        <Outlet/>
      </motion.div>
      <div className={cx("px-6 py-3 block lg:hidden w-full")} >
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-light ">Service delivery platform</h1>
          <FiMenu size={22} className="text-gray-500" onClick={()=> openMobileMenu() } />
        </div>
        <Outlet/>
        <div className="text-left text-xs font-semibold text-gray-500 mt-20" >
          Service delivery platform - 2024 © Taqniyat tech All Rights Reserved.
        </div>
      </div>
      {
        isMobileMenuOpen &&
        <AnimatePresence>
          {isMobileMenuOpen && <MobileSideMenu closeMobileMenu={closeMobileMenu}/>}
        </AnimatePresence>
      }
    </div>
  );
}

export default Root;





