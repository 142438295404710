import React, {useEffect, useState} from 'react';
import {FiCalendar, FiChevronRight, FiHome} from "react-icons/fi";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {getAllOperators} from "../services/technicalService";
import {getServicesByOperator, getServicesRevenue} from "../services/serviceManagementServices";
import {CustomDropdownFilterObject, CustomDropdownObject} from "../ressources/CustomComponents";
import DatePicker from "react-datepicker";
import moment from "moment";
import cx from "classnames";
import {FaFileExport} from "react-icons/fa";
import * as XLSX from "xlsx";
import DataTable from "react-data-table-component";

function ServiceStatistics(props) {

  const {t} = useTranslation();

  const [isOperatorSelectOpen, setIsOperatorSelectOpen] = useState(false);
  const [operator, setOperator] = useState({});
  const [operatorList, setOperatorList] = useState([]);
  const [isServiceSelectOpen, setIsServiceSelectOpen] = useState(false);
  const [service, setService] = useState({});
  const [serviceList, setServiceList] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [revenueData, setRevenueData] = useState([]);
  const [loading, setLoading] = useState(false);

  const selectOperator = (operator) => {
    setOperator(operator);
    setIsOperatorSelectOpen(false);
    setServiceList([]);
  }

  const selectService = (val)=>{
    setService(val);
    setIsServiceSelectOpen(false);
  }

  function exportToExcel(tableData, fileName) {
    const ws = XLSX.utils.json_to_sheet(tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  const fetchOperators = async () => {
    await getAllOperators()
      .then((res)=> setOperatorList(res.results) )
      .catch((err)=>console.log(err));
  }

  const fetchServicesByOperator = async () => {
    await getServicesByOperator(operator.operatorId)
      .then((res)=> setServiceList(res.results) )
      .catch((err)=>console.log(err));
  }

  const totalAmount = (data, rowName) => data.reduce((sum, row) => sum + row[rowName], 0);

  const fetchRevenue = async () =>{
    setLoading(true);
    await getServicesRevenue(operator.operatorId, (service.serviceId || 0), moment(startDate).format('MM/DD/YYYY'), moment(endDate).format('MM/DD/YYYY'))
      .then((res)=> {
        const dataWithTotal = [
          ...res.results,
          {
            id: 'total',
            operatorName: '',
            serviceName: '',
            shortCode: '',
            totalRevenue: parseFloat(totalAmount(res.results, "totalRevenue")).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            totalSpRevenue: parseFloat(totalAmount(res.results, "totalSpRevenue")).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            newSubscription: parseInt(totalAmount(res.results, "newSubscription")),
            unSubscription: parseInt(totalAmount(res.results, "unSubscription")),
            totalSubCount: res.results[res.results?.length -1].totalSubCount ,
            churn: parseInt(totalAmount(res.results, "churn")),
            hits: parseInt(totalAmount(res.results, "hits")),
          },
        ];
        setRevenueData(dataWithTotal);
      })
      .catch((err)=>console.log(err))
      .finally(()=>{
        setLoading(false);
      })
  }

  useEffect(()=>{
    fetchOperators();
  }, []);

  useEffect(()=>{
    if(Object.keys(operator).length > 0){
      fetchServicesByOperator();
    }
  }, [operator]);

  useEffect(() => {
    if(Object.keys(operator).length > 0 && startDate !== null && endDate !== null ){
      fetchRevenue();
    }
  }, [operator, startDate, endDate, service]);

  const columns = [
    {
      name: 'Date',
      selector: row => row.id === "total" ? "Total" : moment(row.dated).format('DD/MM/yyyy'),
      sortable: true,
    }, {
      name: 'Operator Name',
      selector: row => row.operatorName,
      sortable: true,
    }, {
      name: 'Service Name',
      selector: row => row.serviceName,
      sortable: true,
    }, {
      name: 'Short Code',
      selector: row => row.shortCode,
      sortable: true,
      width: '100px'
    }, {
      name: 'Total Revenue SAR',
      selector: row => row.totalRevenue,
      sortable: true,
    }, {
      name: 'Total SP Revenue SAR',
      selector: row => row.totalSpRevenue,
      sortable: true,
    },{
      name: 'New Subscription',
      selector: row => row.newSubscription,
      sortable: true,
      width: '100px'
    }, {
      name: 'Unsubscription',
      selector: row => row.unSubscription,
      sortable: true,
      width: '100px'
    }, {
      name: 'Total Sub Count',
      selector: row => row.totalSubCount,
      sortable: true,
    }, {
      name: 'Churn',
      selector: row => row.churn,
      sortable: true,
      width: '100px'
    }, {
      name: 'Hits',
      selector: row => row.hits,
      sortable: true,
      width: '100px'
    }
  ];

  const conditionalRowStyles = [
    {
      when: row => row.id === 'total',
      style: {
        backgroundColor: '#f0f0f0',
        fontWeight: 'bold',
      },
    },
  ];

  return (
    <div>
      <div>
        <div className="text-gray-500 flex items-center gap-2 mt-2 mb-6">
          <span className="text-xs font-semibold flex items-center">
            <FiHome size={12}/> <FiChevronRight size={12}/>
          </span>
          <h4 className="text-xs font-semibold text-gray-500">{t("SERVICES_STATISTICS.TITLE")}</h4>
        </div>
        <div className="flex items-center justify-between mb-6">
          {/*<div className="w-1/3 text-start">*/}
          {/*  <h1 className="text-xl font-bold">{t("SERVICES_STATISTICS.TITLE")}</h1>*/}
          {/*</div>*/}
          <div className="grid grid-cols-6 items-center gap-4 w-full">
            <div className="w-full col-span-3">
              <CustomDropdownFilterObject
                label="Operators"
                isOpen={isOperatorSelectOpen}
                setIsOpen={setIsOperatorSelectOpen}
                itemList={operatorList}
                selectedItem={operator}
                itemKey="operatorName"
                idKey="operatorId"
                selectItem={selectOperator}
              />
            </div>
            <div className="w-full col-span-3">
              <CustomDropdownFilterObject
                label="Services"
                isOpen={isServiceSelectOpen}
                setIsOpen={setIsServiceSelectOpen}
                itemList={[{serviceId: 0, serviceName: "See all"} ,...serviceList]}
                selectedItem={service}
                itemKey="serviceName"
                idKey="serviceId"
                selectItem={selectService}
                disabled={Object.keys(operator).length === 0}
              />
            </div>
            <div className="border rounded-lg px-2 py-1.5 text-sm flex items-center gap-2 bg-white w-full col-span-2">
              <FiCalendar className="text-gray-400"/>
              <DatePicker
                selected={startDate}
                onChange={(e) => setStartDate(e)}
                type="text"
                dateFormat="dd/MM/yyyy"
                placeholderText='Date'
                isClearable
                disabled={Object.keys(operator).length === 0}
                wrapperClassName="w-full"
                className="w-full"
              />
            </div>
            <div className="border rounded-lg px-2 py-1.5 text-sm flex items-center gap-2 bg-white w-full col-span-2">
              <FiCalendar className="text-gray-400"/>
              <DatePicker
                selected={endDate}
                onChange={(e) => setEndDate(e)}
                type="text"
                dateFormat="dd/MM/yyyy"
                placeholderText='Date'
                isClearable
                disabled={Object.keys(operator).length === 0}
                wrapperClassName="w-full"
                className="w-full"
              />
            </div>
            <button
              onClick={() => exportToExcel(revenueData, `revenue-from-${moment(startDate).format('MM/DD/YYYY')}-to-${moment(endDate).format('MM/DD/YYYY')}`)}
              disabled={revenueData.length <= 0}
              className={cx("bg-azure flex items-center gap-4 px-4 py-1.5 col-span-2 text-sm font-semibold rounded-lg text-white hover:opacity-60 hover:shadow", {
                'bg-azure': revenueData.length > 0,
                'bg-gray-400': revenueData.length <= 0,
              })}
            >
              <FaFileExport size={18}/>
              <span>Export file</span>
            </button>
          </div>
        </div>
      </div>
      {
        loading
          ?
          <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-x-auto'>
            Loading...
          </div>
          :
          <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-x-auto relative z-0'>
            <DataTable
              columns={columns}
              data={revenueData}
              customStyles={{
                headCells: {
                  style: {
                    backgroundColor: '#f3f4f6',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    fontSize: '12px',
                    paddingLeft: '8px',  // Reduce left padding in header cells
                    paddingRight: '8px',
                  },
                },
                cells: {
                  style: {
                    padding: '8px',
                    textAlign: 'left',
                  },
                },
              }}
              highlightOnHover
              pagination={false}
              conditionalRowStyles={conditionalRowStyles}
            />
          </div>
        // <div className='bg-white p-0 rounded-lg mt-4 shadow overflow-x-auto'>
        //   <table className="min-w-full border-collapse" id='my-applications-table'>
        //     <thead className="bg-gray-100 customShadow">
        //     <tr>
        //       <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
        //         {t("SERVICES_STATISTICS.TABLE.DATE")}
        //       </th>
        //       <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
        //         {t("SERVICES_STATISTICS.TABLE.OPERATOR_NAME")}
        //       </th>
        //       <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
        //         {t("SERVICES_STATISTICS.TABLE.SERVICE_NAME")}
        //       </th>
        //       <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
        //         {t("SERVICES_STATISTICS.TABLE.SHORT_CODE")}
        //       </th>
        //       <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
        //         {t("SERVICES_STATISTICS.TABLE.NEW_SUBSCRIPTION")}
        //       </th>
        //       <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
        //         {t("SERVICES_STATISTICS.TABLE.UNSUBSCRIPTION")}
        //       </th>
        //       <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
        //         {t("SERVICES_STATISTICS.TABLE.HITS")}
        //       </th>
        //       <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
        //         {t("SERVICES_STATISTICS.TABLE.TOTAL_SUB_COUNT")}
        //       </th>
        //       <th className="text-left py-3 px-2 uppercase font-semibold text-xs">
        //         {t("SERVICES_STATISTICS.TABLE.TOTAL_REVENUE")}
        //       </th>
        //     </tr>
        //     </thead>
        //     <tbody className="text-gray-800 text-sm">
        //     {
        //       revenueData.map((it, index) =>
        //         <tr key={index.toString()} className={cx('border')}>
        //           <td className="text-left py-3 px-2">{moment(it.dated).calendar()}</td>
        //           <td className="text-left py-3 px-2">{it.operatorName}</td>
        //           <td className="text-left py-3 px-2">{it.serviceName}</td>
        //           <td className="text-left py-3 px-2">{it.shortCode}</td>
        //           <td className="text-left py-3 px-2">{it.newSubscription}</td>
        //           <td className="text-left py-3 px-2">{it.unSubscription}</td>
        //           <td className="text-left py-3 px-2">{it.hits}</td>
        //           <td className="text-left py-3 px-2">{it.totalSubCount}</td>
        //           <td className="text-left py-3 px-2">{it.totalRevenue}</td>
        //         </tr>
        //       )
        //     }
        //     </tbody>
        //   </table>
        // </div>
      }
    </div>
  );
}

export default ServiceStatistics;
