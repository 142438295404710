import api from "./api";


export const getAllCampaigns = async ()=>{
  try {
    const response = await api.get('/LocalApi/Campaigns');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}


export const getAllPublishers = async ()=>{
  try {
    const response = await api.get('/LocalApi/Publishers');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}


export const getAllRegisteredEmails = async ()=>{
  try {
    const response = await api.get('/LocalApi/RegisteredEmails');
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}


export const getCampaignsByPublisher = async (publisherId)=>{
  try {
    const response = await api.get('/LocalApi/CampaignsByPublisher', {
      params: {
        PublisherId: publisherId,
      }
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}


export const getDailyCampaignRevenue = async (campaignId, operatorId, startData, endDate)=>{
  try {
    const response = await api.get('/LocalApi/DailyCampaignRevenue', {
      params: {
        CampaignId: campaignId,
        OperatorId: operatorId,
        FromDate: startData,
        ToDate: endDate,
      }
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}


export const getMonthlyCampaignRevenue = async (campaignId, operatorId, startData, endDate)=>{
  try {
    const response = await api.get('/LocalApi/MonthlyCampaignRevenue', {
      params: {
        CampaignId: campaignId,
        OperatorId: operatorId,
        FromDate: startData,
        ToDate: endDate,
      }
    });
    return response.data;
  } catch (error) {
    console.error("There was an error fetching the data:", error);
    throw error;
  }
}
